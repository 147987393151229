const ROUTES = {
  "/": {
    "name": "HomePageC8e1d4d91f61486eA4f8271f5c55c3bf",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesF309d05069cb4cef85036cc52967a9fd",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageA96f5d5e55dc4395911c927fbcb08889",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage32f07522C09344be879bF190c9ca5d9a",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageA1ea3d19F0ba45d9AcafC25a1acc700b",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage2307312dDcd4495e8bfeB83872e6b690",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages6499c353B06444ca879dC1a082b1647d",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages9f81c9dcA47e449397961e5e6c8f0735",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageBa9dd8e1340045a29767D166210c1e4b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages80a22e8f7ac94451Abc14749a4956145",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;