import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "30938509-119c-48a1-829e-db216b12ec16"
// title: ""
// type: :text
// key: "title"
// parent_id: "ebd4f326-dadd-400c-a5a2-d846e4cb7d39"
export const Title30938509119c48a1829eDb216b12ec16 = "Join Us";

// id: "84cc0469-75db-420d-affc-a4302693b06b"
// title: ""
// type: :text
// key: "title"
// parent_id: "dcd31861-ba3b-45a3-94a4-09c15e5a0e90"
export const Title84cc046975db420dAffcA4302693b06b = "Leveling";

// id: "0037d159-e73c-4758-aebc-865cf6732ab4"
// title: ""
// type: :text
// key: "title"
// parent_id: "c129a8bf-1894-4824-82e5-4362ed6a717d"
export const Title0037d159E73c4758Aebc865cf6732ab4 = "Resources";

// id: "861475cd-6dae-4dba-be4e-95ac79565259"
// title: ""
// type: :reference
// key: "label"
// parent_id: "db02ba19-52e7-4842-9183-139e9d48c0f2"
export function Label861475cd6dae4dbaBe4e95ac79565259(props: any) {
  return (
    <DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label861475cd6dae4dbaBe4e95ac79565259" {...props} />
  );
}

// id: "0dd934de-4653-49a3-b30b-bb2b7c4cc689"
// title: ""
// type: :text
// key: "title"
// parent_id: "3c8711c9-e185-4674-b3f5-433e1844304a"
export const Title0dd934de465349a3B30bBb2b7c4cc689 = "Join Us";

// id: "3bbb3330-f343-429b-962d-f78dee697ed4"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "4d29c700-9b97-4f77-8c11-2156e6f3709c"
export const NavMenuSlug3bbb3330F343429b962dF78dee697ed4 = "members-primary-nav";

// id: "968c7052-d9f3-47af-9e11-f4366d11ea97"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards968c7052D9f347af9e11F4366d11ea97(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards968c7052D9f347af9e11F4366d11ea97", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "157ae88a-9957-4055-bdcd-a0b248bd5911"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title157ae88a99574055BdcdA0b248bd5911 = "Leveling";

// id: "f309d050-69cb-4cef-8503-6cc52967a9fd"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesF309d05069cb4cef85036cc52967a9fd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesF309d05069cb4cef85036cc52967a9fd`}>
      {props.children}
    </div>
  );
}

// id: "4f0cc856-49b6-4cbf-9854-e282c660ab84"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader4f0cc85649b64cbf9854E282c660ab84(props: any) {
  return (
    <ShortHeaderB8fa533fDe8a488391b12c611e044c3b parentTag="DefaultHeader4f0cc85649b64cbf9854E282c660ab84" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "9e87dd82-06b5-4824-9fda-6f0df5f7f973"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973(props: any) {
  return (
    <ShortHeaderB8fa533fDe8a488391b12c611e044c3b parentTag="MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973" header-top={<DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTop46c65d0b82524ee39436207d25b534db" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "a16bd4a6-13cd-4dbc-bd75-b0624bf02215"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215 page-body`}>
      {props["marketingCards"] || <MarketingCards968c7052D9f347af9e11F4366d11ea97 parentTag="MarketingCardsBab8edb9A49944669fa5Ab4343356582" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "a96f5d5e-55dc-4395-911c-927fbcb08889"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageA96f5d5e55dc4395911c927fbcb08889(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageA96f5d5e55dc4395911c927fbcb08889 page`}>
      {props["header"] || <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header2d4f607974364f70994f0823dcf70e61" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215 parentTag="Body30b09f7970d7433788c5043edbc9485d" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterE516f8211d0846a6914b68b11e2f3acf" {...props} />}
    </div>
  );
}

// id: "a1ea3d19-f0ba-45d9-acaf-c25a1acc700b"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageA1ea3d19F0ba45d9AcafC25a1acc700b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageA1ea3d19F0ba45d9AcafC25a1acc700b`}>
      {props["header"] || <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header0f311362Eb814311B1e90e2aa2cde7a4" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer61e87b37B4934d0f88584fdba7ff3052" {...props} />}
    </div>
  );
}

// id: "6499c353-b064-44ca-879d-c1a082b1647d"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages6499c353B06444ca879dC1a082b1647d(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages6499c353B06444ca879dC1a082b1647d gf-page-layout`}>
      {props["header"] || <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header2f35cc2eA5984061B9613e7be2d93215" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavF4ac173eC19547768732F0a217e0d838", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer7ed73e1c10f145e98604C301e48e471c" {...props} />}
    </div>
  );
}

// id: "22bf606c-37bd-44d5-b192-24e7bf17491d"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault22bf606c37bd44d5B19224e7bf17491d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault22bf606c37bd44d5B19224e7bf17491d page`}>
      {props["header"] || <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header9b66faa7Af1d48758bb8Acd4f3194e14" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterF7bbcaa272a24da29d98C9d1fc363115" {...props} />}
    </div>
  );
}

// id: "35d64dc4-2119-433f-87c2-506656a12d93"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage35d64dc42119433f87c2506656a12d93(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage35d64dc42119433f87c2506656a12d93`}>
      {props["header"] || <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderF7181994B0944bc2B1e3F7a9faa66a9e" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterA2537e4c1900408cB9ca09c10e038741" {...props} />}
    </div>
  );
}

// id: "ba9dd8e1-3400-45a2-9767-d166210c1e4b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageBa9dd8e1340045a29767D166210c1e4b(props: any) {
  return (
    <SecondaryPage35d64dc42119433f87c2506656a12d93 parentTag="ResourcesPageBa9dd8e1340045a29767D166210c1e4b" header={<SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderC129a8bf1894482482e54362ed6a717d" title="Resources" />} {...props} />
  );
}

// id: "745910c4-3279-4224-844a-1b1175f26244"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label745910c432794224844a1b1175f26244(props: any) {
  return (
    <DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label745910c432794224844a1b1175f26244" {...props} />
  );
}

// id: "b8fa533f-de8a-4883-91b1-2c611e044c3b"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderB8fa533fDe8a488391b12c611e044c3b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderB8fa533fDe8a488391b12c611e044c3b site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo7dcf9ee66419406d82a23902cfba0334", parentTag)} label={<DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label113003eb90da4594B4bbBf3794a73f00" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation4d29c7009b974f778c112156e6f3709c", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "36fdaa12-2b45-423f-8027-99feb524bf9a"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo36fdaa122b45423f802799feb524bf9a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogo36fdaa122b45423f802799feb524bf9a logo`} alt="logo" />
  );
}

// id: "9a45a3b8-09d4-402b-9182-306cebc0b736"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote9a45a3b809d4402b9182306cebc0b736(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote9a45a3b809d4402b9182306cebc0b736`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "2307312d-dcd4-495e-8bfe-b83872e6b690"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage2307312dDcd4495e8bfeB83872e6b690(props: any) {
  return (
    <SecondaryPage35d64dc42119433f87c2506656a12d93 parentTag="JoinUsPage2307312dDcd4495e8bfeB83872e6b690" header={<SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderEbd4f326Dadd400cA5a2D846e4cb7d39" title="Join Us" />} {...props} />
  );
}

// id: "1babb105-99c1-42bd-a3d6-8354abe084d1"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href1babb10599c142bdA3d68354abe084d1 = "/";

// id: "c8186b03-b151-4434-9f0d-9a689a6a39dc"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterC8186b03B15144349f0d9a689a6a39dc(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterC8186b03B15144349f0d9a689a6a39dc" {...props} />
  );
}

// id: "80a22e8f-7ac9-4451-abc1-4749a4956145"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages80a22e8f7ac94451Abc14749a4956145(props: any) {
  return (
    <SecondaryPage35d64dc42119433f87c2506656a12d93 parentTag="SignupPages80a22e8f7ac94451Abc14749a4956145" header={<SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header3c8711c9E1854674B3f5433e1844304a" title="Join Us" />} {...props} />
  );
}

// id: "93751908-79a1-490e-8e15-d13bbedb7e24"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero9375190879a1490e8e15D13bbedb7e24(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero9375190879a1490e8e15D13bbedb7e24 hero`}>
      <div className="title">
        {props["title"] || Title6ed22d09Ed6548a0B9d5C74794fce11f}
      </div>
    </div>
  );
}

// id: "8a090936-bad2-472e-916c-d58c5f63a2a5"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header8a090936Bad2472e916cD58c5f63a2a5(props: any) {
  return (
    <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header8a090936Bad2472e916cD58c5f63a2a5" {...props} />
  );
}

// id: "c732fa44-5606-49ba-9267-8a2ae9a348db"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterC732fa44560649ba92678a2ae9a348db(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterC732fa44560649ba92678a2ae9a348db footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links87e2cfda2f3e48a398a0Eba0198597c3", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoC35c1b3e9770408dBed7Fb7d9ab13740 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright86efb137Eda3412498233bec01f1699b", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowDe79ff87Abe74b7eA16d0d289e5d6ad7", parentTag)} {...props} />}
    </div>
  );
}

// id: "c3052fcd-ed6e-4f1e-8262-4a24306ab0ac"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoDb02ba1952e748429183139e9d48c0f2", parentTag)} label={<DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label861475cd6dae4dbaBe4e95ac79565259" alt="logo" />} url="/" className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationDf688ef2597f4cdcAede9d04b6afea1f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "32f07522-c093-44be-879b-f190c9ca5d9a"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage32f07522C09344be879bF190c9ca5d9a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage32f07522C09344be879bF190c9ca5d9a`}>
      {props["header"] || <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderDcd31861Ba3b45a394a409c15e5a0e90" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer8d2b5699769941ccB61b5caf31ca08a3" {...props} />}
    </div>
  );
}

// id: "9f81c9dc-a47e-4493-9796-1e5e6c8f0735"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages9f81c9dcA47e449397961e5e6c8f0735(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages9f81c9dcA47e449397961e5e6c8f0735`}>
      {props["header"] || <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header80dfb94328f7445aA3a4703811233ecb" {...props} />}
      {props.children}
    </div>
  );
}

// id: "18269d7c-f9bc-48a1-8248-eb26fa5f2a0d"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero18269d7cF9bc48a18248Eb26fa5f2a0d(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero18269d7cF9bc48a18248Eb26fa5f2a0d hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button4f283a555c514e31A2de690de83464c4", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "4f9019cd-18e6-403b-a659-18d0eca35b60"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTop536cbfc9E2f64b58826c62888f7fe9ce" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero9375190879a1490e8e15D13bbedb7e24 parentTag="HeroC31b099e413a4204A457Ce8b78a0125c" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageC8e1d4d91f61486eA4f8271f5c55c3bf(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageC8e1d4d91f61486eA4f8271f5c55c3bf page`}>
      {props["header"] || <HomeHeaderF77e68172d834ab6Baca1de3c8d36445 parentTag="HeaderCe5fa6345b2840cd8313C701e46f9f48" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA69900ab40344eb3Ab8b5a3bd1b8fba7", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215 parentTag="Body5fd05738Af844d5cBeb7926312e39391" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote34c95384064c4c1a90f373ecd62988b9", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterEb7197485d804eca9db5Dd2cebe0dd17" {...props} />}
    </div>
  );
}

// id: "f77e6817-2d83-4ab6-baca-1de3c8d36445"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderF77e68172d834ab6Baca1de3c8d36445(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderF77e68172d834ab6Baca1de3c8d36445 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTopF3e603e3Ff3f44f29448A0d7e2427494" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHero18269d7cF9bc48a18248Eb26fa5f2a0d parentTag="Hero9b9f54e83b7349f9B8141ef5b3edcd02" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "87e2cfda-2f3e-48a3-98a0-eba0198597c3"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "c732fa44-5606-49ba-9267-8a2ae9a348db"
export function Links87e2cfda2f3e48a398a0Eba0198597c3(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links87e2cfda2f3e48a398a0Eba0198597c3", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "c31b099e-413a-4204-a457-ce8b78a0125c"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4f9019cd-18e6-403b-a659-18d0eca35b60"
export function HeroC31b099e413a4204A457Ce8b78a0125c(props: any) {
  return (
    <SecondaryMarketingHero9375190879a1490e8e15D13bbedb7e24 parentTag="HeroC31b099e413a4204A457Ce8b78a0125c" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "7ed73e1c-10f1-45e9-8604-c301e48e471c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "6499c353-b064-44ca-879d-c1a082b1647d"
export function Footer7ed73e1c10f145e98604C301e48e471c(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer7ed73e1c10f145e98604C301e48e471c" {...props} />
  );
}

// id: "9b66faa7-af1d-4875-8bb8-acd4f3194e14"
// title: ""
// type: :reference
// key: "header"
// parent_id: "22bf606c-37bd-44d5-b192-24e7bf17491d"
export function Header9b66faa7Af1d48758bb8Acd4f3194e14(props: any) {
  return (
    <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header9b66faa7Af1d48758bb8Acd4f3194e14" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "2f35cc2e-a598-4061-b961-3e7be2d93215"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6499c353-b064-44ca-879d-c1a082b1647d"
export function Header2f35cc2eA5984061B9613e7be2d93215(props: any) {
  return (
    <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header2f35cc2eA5984061B9613e7be2d93215" {...props} />
  );
}

// id: "ce5fa634-5b28-40cd-8313-c701e46f9f48"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
export function HeaderCe5fa6345b2840cd8313C701e46f9f48(props: any) {
  return (
    <HomeHeaderF77e68172d834ab6Baca1de3c8d36445 parentTag="HeaderCe5fa6345b2840cd8313C701e46f9f48" {...props} />
  );
}

// id: "a69900ab-4034-4eb3-ab8b-5a3bd1b8fba7"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
export function WelcomeBackA69900ab40344eb3Ab8b5a3bd1b8fba7(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA69900ab40344eb3Ab8b5a3bd1b8fba7", parentTag)} {...props} />
  );
}

// id: "e516f821-1d08-46a6-914b-68b11e2f3acf"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a96f5d5e-55dc-4395-911c-927fbcb08889"
export function FooterE516f8211d0846a6914b68b11e2f3acf(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterE516f8211d0846a6914b68b11e2f3acf" {...props} />
  );
}

// id: "f7bbcaa2-72a2-4da2-9d98-c9d1fc363115"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "22bf606c-37bd-44d5-b192-24e7bf17491d"
export function FooterF7bbcaa272a24da29d98C9d1fc363115(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterF7bbcaa272a24da29d98C9d1fc363115" {...props} />
  );
}

// id: "eb719748-5d80-4eca-9db5-dd2cebe0dd17"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
export function FooterEb7197485d804eca9db5Dd2cebe0dd17(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterEb7197485d804eca9db5Dd2cebe0dd17" {...props} />
  );
}

// id: "f7181994-b094-4bc2-b1e3-f7a9faa66a9e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "35d64dc4-2119-433f-87c2-506656a12d93"
export function HeaderF7181994B0944bc2B1e3F7a9faa66a9e(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderF7181994B0944bc2B1e3F7a9faa66a9e" title="Secondary Page" {...props} />
  );
}

// id: "1130ac80-45e8-4949-bad5-b2a88af87289"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "968c7052-d9f3-47af-9e11-f4366d11ea97"
export const PostSlug1130ac8045e84949Bad5B2a88af87289 = "home-page-posts2";

// id: "80dfb943-28f7-445a-a3a4-703811233ecb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9f81c9dc-a47e-4493-9796-1e5e6c8f0735"
export function Header80dfb94328f7445aA3a4703811233ecb(props: any) {
  return (
    <MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973 parentTag="Header80dfb94328f7445aA3a4703811233ecb" {...props} />
  );
}

// id: "db02ba19-52e7-4842-9183-139e9d48c0f2"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "c3052fcd-ed6e-4f1e-8262-4a24306ab0ac"
export function LinkedLogoDb02ba1952e748429183139e9d48c0f2(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoDb02ba1952e748429183139e9d48c0f2", parentTag)} label={<DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label861475cd6dae4dbaBe4e95ac79565259" alt="logo" />} url="/" className="linked-logo" {...props} />
  );
}

// id: "5fd05738-af84-4d5c-beb7-926312e39391"
// title: ""
// type: :reference
// key: "body"
// parent_id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
export function Body5fd05738Af844d5cBeb7926312e39391(props: any) {
  return (
    <MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215 parentTag="Body5fd05738Af844d5cBeb7926312e39391" {...props} />
  );
}

// id: "dcd31861-ba3b-45a3-94a4-09c15e5a0e90"
// title: ""
// type: :reference
// key: "header"
// parent_id: "32f07522-c093-44be-879b-f190c9ca5d9a"
export function HeaderDcd31861Ba3b45a394a409c15e5a0e90(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderDcd31861Ba3b45a394a409c15e5a0e90" title="Leveling" {...props} />
  );
}

// id: "9b9f54e8-3b73-49f9-b814-1ef5b3edcd02"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "f77e6817-2d83-4ab6-baca-1de3c8d36445"
export function Hero9b9f54e83b7349f9B8141ef5b3edcd02(props: any) {
  return (
    <HomePageHero18269d7cF9bc48a18248Eb26fa5f2a0d parentTag="Hero9b9f54e83b7349f9B8141ef5b3edcd02" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "8d4b0a9a-9331-4559-9162-fb655bd8c6b3"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "968c7052-d9f3-47af-9e11-f4366d11ea97"
export const ButtonClass8d4b0a9a933145599162Fb655bd8c6b3 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "f4ac173e-c195-4776-8732-f0a217e0d838"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "6499c353-b064-44ca-879d-c1a082b1647d"
export function SecondaryNavF4ac173eC19547768732F0a217e0d838(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavF4ac173eC19547768732F0a217e0d838", parentTag)} {...props} />
  );
}

// id: "86efb137-eda3-4124-9823-3bec01f1699b"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "c732fa44-5606-49ba-9267-8a2ae9a348db"
export function Copyright86efb137Eda3412498233bec01f1699b(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright86efb137Eda3412498233bec01f1699b", parentTag)} {...props} />
  );
}

// id: "7dcf9ee6-6419-406d-82a2-3902cfba0334"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "b8fa533f-de8a-4883-91b1-2c611e044c3b"
export function LinkedLogo7dcf9ee66419406d82a23902cfba0334(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo7dcf9ee66419406d82a23902cfba0334", parentTag)} label={<DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label113003eb90da4594B4bbBf3794a73f00" alt="logo" />} {...props} />
  );
}

// id: "3c8711c9-e185-4674-b3f5-433e1844304a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "80a22e8f-7ac9-4451-abc1-4749a4956145"
export function Header3c8711c9E1854674B3f5433e1844304a(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header3c8711c9E1854674B3f5433e1844304a" title="Join Us" {...props} />
  );
}

// id: "d66d356a-8c56-4533-9493-96d03aadb6a6"
// title: ""
// type: :text
// key: "title"
// parent_id: "4f0cc856-49b6-4cbf-9854-e282c660ab84"
export const TitleD66d356a8c564533949396d03aadb6a6 = null;

// id: "760127b1-3df2-43e0-90bc-f0d5802de077"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "b8fa533f-de8a-4883-91b1-2c611e044c3b"
export const BackgroundImage760127b13df243e090bcF0d5802de077 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "bab8edb9-a499-4466-9fa5-ab4343356582"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "a16bd4a6-13cd-4dbc-bd75-b0624bf02215"
export function MarketingCardsBab8edb9A49944669fa5Ab4343356582(props: any) {
  return (
    <MarketingCards968c7052D9f347af9e11F4366d11ea97 parentTag="MarketingCardsBab8edb9A49944669fa5Ab4343356582" postSlug="home-page-posts2" {...props} />
  );
}

// id: "4f283a55-5c51-4e31-a2de-690de83464c4"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "18269d7c-f9bc-48a1-8248-eb26fa5f2a0d"
export function Button4f283a555c514e31A2de690de83464c4(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button4f283a555c514e31A2de690de83464c4", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "0f311362-eb81-4311-b1e9-0e2aa2cde7a4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a1ea3d19-f0ba-45d9-acaf-c25a1acc700b"
export function Header0f311362Eb814311B1e90e2aa2cde7a4(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header0f311362Eb814311B1e90e2aa2cde7a4" title="Public Events" {...props} />
  );
}

// id: "a2537e4c-1900-408c-b9ca-09c10e038741"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "35d64dc4-2119-433f-87c2-506656a12d93"
export function FooterA2537e4c1900408cB9ca09c10e038741(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="FooterA2537e4c1900408cB9ca09c10e038741" {...props} />
  );
}

// id: "8d2b5699-7699-41cc-b61b-5caf31ca08a3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "32f07522-c093-44be-879b-f190c9ca5d9a"
export function Footer8d2b5699769941ccB61b5caf31ca08a3(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer8d2b5699769941ccB61b5caf31ca08a3" {...props} />
  );
}

// id: "df688ef2-597f-4cdc-aede-9d04b6afea1f"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "c3052fcd-ed6e-4f1e-8262-4a24306ab0ac"
export function NavigationDf688ef2597f4cdcAede9d04b6afea1f(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationDf688ef2597f4cdcAede9d04b6afea1f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "2d4f6079-7436-4f70-994f-0823dcf70e61"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a96f5d5e-55dc-4395-911c-927fbcb08889"
export function Header2d4f607974364f70994f0823dcf70e61(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="Header2d4f607974364f70994f0823dcf70e61" title="About Us" {...props} />
  );
}

// id: "536cbfc9-e2f6-4b58-826c-62888f7fe9ce"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4f9019cd-18e6-403b-a659-18d0eca35b60"
export function HeaderTop536cbfc9E2f64b58826c62888f7fe9ce(props: any) {
  return (
    <DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTop536cbfc9E2f64b58826c62888f7fe9ce" {...props} />
  );
}

// id: "61e87b37-b493-4d0f-8858-4fdba7ff3052"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a1ea3d19-f0ba-45d9-acaf-c25a1acc700b"
export function Footer61e87b37B4934d0f88584fdba7ff3052(props: any) {
  return (
    <DefaultFooterC732fa44560649ba92678a2ae9a348db parentTag="Footer61e87b37B4934d0f88584fdba7ff3052" {...props} />
  );
}

// id: "6ed22d09-ed65-48a0-b9d5-c74794fce11f"
// title: ""
// type: :text
// key: "title"
// parent_id: "93751908-79a1-490e-8e15-d13bbedb7e24"
export const Title6ed22d09Ed6548a0B9d5C74794fce11f = "Default Title";

// id: "c129a8bf-1894-4824-82e5-4362ed6a717d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ba9dd8e1-3400-45a2-9767-d166210c1e4b"
export function HeaderC129a8bf1894482482e54362ed6a717d(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderC129a8bf1894482482e54362ed6a717d" title="Resources" {...props} />
  );
}

// id: "d8a62550-ce44-43a7-b1d7-6db79c99c15d"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "f77e6817-2d83-4ab6-baca-1de3c8d36445"
export const BackgroundImageD8a62550Ce4443a7B1d76db79c99c15d = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "4d29c700-9b97-4f77-8c11-2156e6f3709c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "b8fa533f-de8a-4883-91b1-2c611e044c3b"
export function Navigation4d29c7009b974f778c112156e6f3709c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation4d29c7009b974f778c112156e6f3709c", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "c35c1b3e-9770-408d-bed7-fb7d9ab13740"
// title: ""
// type: :html
// key: "logo"
// parent_id: "c732fa44-5606-49ba-9267-8a2ae9a348db"
export function LogoC35c1b3e9770408dBed7Fb7d9ab13740(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoC35c1b3e9770408dBed7Fb7d9ab13740 logo`} />
  );
}

// id: "46c65d0b-8252-4ee3-9436-207d25b534db"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "9e87dd82-06b5-4824-9fda-6f0df5f7f973"
export function HeaderTop46c65d0b82524ee39436207d25b534db(props: any) {
  return (
    <DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTop46c65d0b82524ee39436207d25b534db" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ebd4f326-dadd-400c-a5a2-d846e4cb7d39"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2307312d-dcd4-495e-8bfe-b83872e6b690"
export function HeaderEbd4f326Dadd400cA5a2D846e4cb7d39(props: any) {
  return (
    <SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60 parentTag="HeaderEbd4f326Dadd400cA5a2D846e4cb7d39" title="Join Us" {...props} />
  );
}

// id: "fb224536-ad4e-4dec-8915-fb7190826993"
// title: ""
// type: :text
// key: "alt"
// parent_id: "36fdaa12-2b45-423f-8027-99feb524bf9a"
export const AltFb224536Ad4e4dec8915Fb7190826993 = "logo";

// id: "de79ff87-abe7-4b7e-a16d-0d289e5d6ad7"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "c732fa44-5606-49ba-9267-8a2ae9a348db"
export function GroupflowDe79ff87Abe74b7eA16d0d289e5d6ad7(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowDe79ff87Abe74b7eA16d0d289e5d6ad7", parentTag)} {...props} />
  );
}

// id: "8a29d69f-5d7d-452c-a2b8-a713a99284d6"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "4f0cc856-49b6-4cbf-9854-e282c660ab84"
export const NavMenuSlug8a29d69f5d7d452cA2b8A713a99284d6 = "marketing-primary-nav";

// id: "30b09f79-70d7-4337-88c5-043edbc9485d"
// title: ""
// type: :reference
// key: "body"
// parent_id: "a96f5d5e-55dc-4395-911c-927fbcb08889"
export function Body30b09f7970d7433788c5043edbc9485d(props: any) {
  return (
    <MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215 parentTag="Body30b09f7970d7433788c5043edbc9485d" postSlug="about-us-posts" {...props} />
  );
}

// id: "34c95384-064c-4c1a-90f3-73ecd62988b9"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "c8e1d4d9-1f61-486e-a4f8-271f5c55c3bf"
export function Quote34c95384064c4c1a90f373ecd62988b9(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote34c95384064c4c1a90f373ecd62988b9", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "a7d391c6-8d7c-44f3-aeca-e763d638d677"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "36fdaa12-2b45-423f-8027-99feb524bf9a"
export const ImageUrlA7d391c68d7c44f3AecaE763d638d677 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "63d26781-b4b7-4c1b-94a4-81814f3f909e"
// title: ""
// type: :text
// key: "title"
// parent_id: "4f9019cd-18e6-403b-a659-18d0eca35b60"
export const Title63d26781B4b74c1b94a481814f3f909e = "Secondary Marketing Title 1";

// id: "f3e603e3-ff3f-44f2-9448-a0d7e2427494"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "f77e6817-2d83-4ab6-baca-1de3c8d36445"
export function HeaderTopF3e603e3Ff3f44f29448A0d7e2427494(props: any) {
  return (
    <DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac parentTag="HeaderTopF3e603e3Ff3f44f29448A0d7e2427494" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "7ff0761f-288d-44b4-9e19-3c01f649c0fb"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "9b9f54e8-3b73-49f9-b814-1ef5b3edcd02"
export const WidgetId7ff0761f288d44b49e193c01f649c0fb = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "26649efc-a679-4cc6-9d13-7da422786230"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "f3e603e3-ff3f-44f2-9448-a0d7e2427494"
export const WidgetId26649efcA6794cc69d137da422786230 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "f35d2979-027e-4887-976a-bcd1169505fa"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "46c65d0b-8252-4ee3-9436-207d25b534db"
export const NavMenuSlugF35d2979027e4887976aBcd1169505fa = "members-primary-nav";

// id: "fd466113-f51e-44cb-827e-4e9640c1269f"
// title: ""
// type: :text
// key: "href"
// parent_id: "4f283a55-5c51-4e31-a2de-690de83464c4"
export const HrefFd466113F51e44cb827e4e9640c1269f = "/join";

// id: "1afa5b00-cd8c-4b47-af3b-0b69a2c281d7"
// title: ""
// type: :text
// key: "title"
// parent_id: "0f311362-eb81-4311-b1e9-0e2aa2cde7a4"
export const Title1afa5b00Cd8c4b47Af3b0b69a2c281d7 = "Public Events";

// id: "69be5f65-19f8-4350-9373-84601d14070f"
// title: ""
// type: :text
// key: "label"
// parent_id: "4f283a55-5c51-4e31-a2de-690de83464c4"
export const Label69be5f6519f84350937384601d14070f = "Join Us";

// id: "bc240361-aa74-4190-af27-627a6fd8dc92"
// title: ""
// type: :text
// key: "url"
// parent_id: "db02ba19-52e7-4842-9183-139e9d48c0f2"
export const UrlBc240361Aa744190Af27627a6fd8dc92 = "/";

// id: "0e93fd6b-333f-4acc-9d70-c694c970bc2c"
// title: ""
// type: :text
// key: "class"
// parent_id: "df688ef2-597f-4cdc-aede-9d04b6afea1f"
export const Class0e93fd6b333f4acc9d70C694c970bc2c = "navigation";

// id: "0185a659-4072-4c76-97b0-8a4ec34f98e1"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "34c95384-064c-4c1a-90f3-73ecd62988b9"
export const ContentSlug0185a65940724c7697b08a4ec34f98e1 = "home-page-quote";

// id: "0537541e-d844-4235-9eab-61e79b13afde"
// title: ""
// type: :text
// key: "title"
// parent_id: "f7181994-b094-4bc2-b1e3-f7a9faa66a9e"
export const Title0537541eD84442359eab61e79b13afde = "Secondary Page";

// id: "e356b710-e4d8-4208-9664-4cd904db3c3b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "87e2cfda-2f3e-48a3-98a0-eba0198597c3"
export const NavMenuSlugE356b710E4d8420896644cd904db3c3b = "footer-nav";

// id: "fcb1d209-faa8-484b-b75d-eaa6adc9a2d8"
// title: ""
// type: :text
// key: "class"
// parent_id: "db02ba19-52e7-4842-9183-139e9d48c0f2"
export const ClassFcb1d209Faa8484bB75dEaa6adc9a2d8 = "linked-logo";

// id: "476b4341-ef6f-44fb-a8bb-1a50bc40f978"
// title: ""
// type: :text
// key: "title"
// parent_id: "c31b099e-413a-4204-a457-ce8b78a0125c"
export const Title476b4341Ef6f44fbA8bb1a50bc40f978 = "Secondary Marketing Title 2";

// id: "a958cbb1-4dbc-4ff2-b8d8-b561f60a9a2e"
// title: ""
// type: :text
// key: "title"
// parent_id: "2d4f6079-7436-4f70-994f-0823dcf70e61"
export const TitleA958cbb14dbc4ff2B8d8B561f60a9a2e = "About Us";

// id: "5346a9a0-8bbc-4371-af5c-c744f665e34f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "9b66faa7-af1d-4875-8bb8-acd4f3194e14"
export const NavMenuSlug5346a9a08bbc4371Af5cC744f665e34f = "members-primary-nav";

// id: "847b5f04-bafa-4194-9b7e-84b6b6062386"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "df688ef2-597f-4cdc-aede-9d04b6afea1f"
export const NavMenuSlug847b5f04Bafa41949b7e84b6b6062386 = "marketing-primary-nav";

// id: "cd527d38-4efa-44f7-9961-7e53cde14067"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "bab8edb9-a499-4466-9fa5-ab4343356582"
export const PostSlugCd527d384efa44f799617e53cde14067 = "home-page-posts2";

// id: "113003eb-90da-4594-b4bb-bf3794a73f00"
// title: ""
// type: :reference
// key: "label"
// parent_id: "7dcf9ee6-6419-406d-82a2-3902cfba0334"
export function Label113003eb90da4594B4bbBf3794a73f00(props: any) {
  return (
    <DefaultLogo36fdaa122b45423f802799feb524bf9a parentTag="Label113003eb90da4594B4bbBf3794a73f00" {...props} />
  );
}

// id: "eb7d3403-fc35-4271-8489-f0f1fafd6d01"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "30b09f79-70d7-4337-88c5-043edbc9485d"
export const PostSlugEb7d3403Fc3542718489F0f1fafd6d01 = "about-us-posts";

const Components = {
  Title30938509119c48a1829eDb216b12ec16,
  Title84cc046975db420dAffcA4302693b06b,
  Title0037d159E73c4758Aebc865cf6732ab4,
  Label861475cd6dae4dbaBe4e95ac79565259,
  Title0dd934de465349a3B30bBb2b7c4cc689,
  NavMenuSlug3bbb3330F343429b962dF78dee697ed4,
  MarketingCards968c7052D9f347af9e11F4366d11ea97,
  Title157ae88a99574055BdcdA0b248bd5911,
  AdminPagesF309d05069cb4cef85036cc52967a9fd,
  DefaultHeader4f0cc85649b64cbf9854E282c660ab84,
  MembersAreaHeader9e87dd8206b548249fda6f0df5f7f973,
  MarketingBodyA16bd4a613cd4dbcBd75B0624bf02215,
  AboutUsPageA96f5d5e55dc4395911c927fbcb08889,
  PublicEventsPageA1ea3d19F0ba45d9AcafC25a1acc700b,
  MemberPages6499c353B06444ca879dC1a082b1647d,
  MembersAreaDefault22bf606c37bd44d5B19224e7bf17491d,
  SecondaryPage35d64dc42119433f87c2506656a12d93,
  ResourcesPageBa9dd8e1340045a29767D166210c1e4b,
  Label745910c432794224844a1b1175f26244,
  ShortHeaderB8fa533fDe8a488391b12c611e044c3b,
  DefaultLogo36fdaa122b45423f802799feb524bf9a,
  PageQuote9a45a3b809d4402b9182306cebc0b736,
  JoinUsPage2307312dDcd4495e8bfeB83872e6b690,
  Href1babb10599c142bdA3d68354abe084d1,
  FooterC8186b03B15144349f0d9a689a6a39dc,
  SignupPages80a22e8f7ac94451Abc14749a4956145,
  SecondaryMarketingHero9375190879a1490e8e15D13bbedb7e24,
  Header8a090936Bad2472e916cD58c5f63a2a5,
  DefaultFooterC732fa44560649ba92678a2ae9a348db,
  DefaultHeaderTopC3052fcdEd6e4f1e82624a24306ab0ac,
  AboutLevelingPage32f07522C09344be879bF190c9ca5d9a,
  MessagePages9f81c9dcA47e449397961e5e6c8f0735,
  HomePageHero18269d7cF9bc48a18248Eb26fa5f2a0d,
  SecondaryMarketingHeader4f9019cd18e6403bA65918d0eca35b60,
  HomePageC8e1d4d91f61486eA4f8271f5c55c3bf,
  HomeHeaderF77e68172d834ab6Baca1de3c8d36445,
  Links87e2cfda2f3e48a398a0Eba0198597c3,
  HeroC31b099e413a4204A457Ce8b78a0125c,
  Footer7ed73e1c10f145e98604C301e48e471c,
  Header9b66faa7Af1d48758bb8Acd4f3194e14,
  Header2f35cc2eA5984061B9613e7be2d93215,
  HeaderCe5fa6345b2840cd8313C701e46f9f48,
  WelcomeBackA69900ab40344eb3Ab8b5a3bd1b8fba7,
  FooterE516f8211d0846a6914b68b11e2f3acf,
  FooterF7bbcaa272a24da29d98C9d1fc363115,
  FooterEb7197485d804eca9db5Dd2cebe0dd17,
  HeaderF7181994B0944bc2B1e3F7a9faa66a9e,
  PostSlug1130ac8045e84949Bad5B2a88af87289,
  Header80dfb94328f7445aA3a4703811233ecb,
  LinkedLogoDb02ba1952e748429183139e9d48c0f2,
  Body5fd05738Af844d5cBeb7926312e39391,
  HeaderDcd31861Ba3b45a394a409c15e5a0e90,
  Hero9b9f54e83b7349f9B8141ef5b3edcd02,
  ButtonClass8d4b0a9a933145599162Fb655bd8c6b3,
  SecondaryNavF4ac173eC19547768732F0a217e0d838,
  Copyright86efb137Eda3412498233bec01f1699b,
  LinkedLogo7dcf9ee66419406d82a23902cfba0334,
  Header3c8711c9E1854674B3f5433e1844304a,
  TitleD66d356a8c564533949396d03aadb6a6,
  BackgroundImage760127b13df243e090bcF0d5802de077,
  MarketingCardsBab8edb9A49944669fa5Ab4343356582,
  Button4f283a555c514e31A2de690de83464c4,
  Header0f311362Eb814311B1e90e2aa2cde7a4,
  FooterA2537e4c1900408cB9ca09c10e038741,
  Footer8d2b5699769941ccB61b5caf31ca08a3,
  NavigationDf688ef2597f4cdcAede9d04b6afea1f,
  Header2d4f607974364f70994f0823dcf70e61,
  HeaderTop536cbfc9E2f64b58826c62888f7fe9ce,
  Footer61e87b37B4934d0f88584fdba7ff3052,
  Title6ed22d09Ed6548a0B9d5C74794fce11f,
  HeaderC129a8bf1894482482e54362ed6a717d,
  BackgroundImageD8a62550Ce4443a7B1d76db79c99c15d,
  Navigation4d29c7009b974f778c112156e6f3709c,
  LogoC35c1b3e9770408dBed7Fb7d9ab13740,
  HeaderTop46c65d0b82524ee39436207d25b534db,
  HeaderEbd4f326Dadd400cA5a2D846e4cb7d39,
  AltFb224536Ad4e4dec8915Fb7190826993,
  GroupflowDe79ff87Abe74b7eA16d0d289e5d6ad7,
  NavMenuSlug8a29d69f5d7d452cA2b8A713a99284d6,
  Body30b09f7970d7433788c5043edbc9485d,
  Quote34c95384064c4c1a90f373ecd62988b9,
  ImageUrlA7d391c68d7c44f3AecaE763d638d677,
  Title63d26781B4b74c1b94a481814f3f909e,
  HeaderTopF3e603e3Ff3f44f29448A0d7e2427494,
  WidgetId7ff0761f288d44b49e193c01f649c0fb,
  WidgetId26649efcA6794cc69d137da422786230,
  NavMenuSlugF35d2979027e4887976aBcd1169505fa,
  HrefFd466113F51e44cb827e4e9640c1269f,
  Title1afa5b00Cd8c4b47Af3b0b69a2c281d7,
  Label69be5f6519f84350937384601d14070f,
  UrlBc240361Aa744190Af27627a6fd8dc92,
  Class0e93fd6b333f4acc9d70C694c970bc2c,
  ContentSlug0185a65940724c7697b08a4ec34f98e1,
  Title0537541eD84442359eab61e79b13afde,
  NavMenuSlugE356b710E4d8420896644cd904db3c3b,
  ClassFcb1d209Faa8484bB75dEaa6adc9a2d8,
  Title476b4341Ef6f44fbA8bb1a50bc40f978,
  TitleA958cbb14dbc4ff2B8d8B561f60a9a2e,
  NavMenuSlug5346a9a08bbc4371Af5cC744f665e34f,
  NavMenuSlug847b5f04Bafa41949b7e84b6b6062386,
  PostSlugCd527d384efa44f799617e53cde14067,
  Label113003eb90da4594B4bbBf3794a73f00,
  PostSlugEb7d3403Fc3542718489F0f1fafd6d01
}

export default Components;